import React, { Component } from "react";
import ReferenceCard from "../../components/referenceCard/ReferenceCard.js";
import "./ReferenceAccordion.css";
import { Accordion, Panel } from "baseui/accordion";

class ReferenceAccordion extends Component {
  render() {
    const theme = this.props.theme;
    const ReactGA = this.props.ReactGA;
    return (
      <div className="reference-accord">
        <Accordion
          onChange={({ expanded }) =>
            ReactGA.event({
              category: "Reference",
              action: "Clicked: " + expanded,
            })
          }
        >
          {this.props.sections.map((section) => {
            return (
              <Panel
                className="accord-panel"
                title={section["title"]}
                key={section["title"]}
              >
                {section["references"].map((reference) => {
                  return (
                    <ReferenceCard
                      key={reference.key}
                      reference={reference}
                      theme={theme}
                    />
                  );
                })}
              </Panel>
            );
          })}
        </Accordion>
      </div>
    );
  }
}

export default ReferenceAccordion;
