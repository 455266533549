import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ReferenceAccordion from "../../containers/referenceAccordion/ReferenceAccordion.js";
import "./Reference.css";
import { reference } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ReferenceImg from "./ReferenceImg";

class Reference extends Component {
  render() {
    const theme = this.props.theme;
    const ReactGA = this.props.ReactGA;
    ReactGA.pageview("/references");
    return (
      <div className="reference-main">
        <Header theme={theme} />
        <div className="basic-reference">
          <Fade bottom duration={2000} distance="40px">
            <div className="reference-heading-div">
              <div className="reference-heading-img-div">
                {/* <img
									src={require(`../../assests/images/${reference["header_image_path"]}`)}
									alt=""
								/> */}
                <ReferenceImg theme={theme} />
              </div>
              <div className="reference-heading-text-div">
                <h1
                  className="reference-heading-text"
                  style={{ color: theme.text }}
                >
                  {reference.title}
                </h1>
                <h3
                  className="reference-heading-sub-text"
                  style={{ color: theme.text }}
                >
                  {reference["subtitle"]}
                </h3>
                <p
                  className="reference-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {reference["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <ReferenceAccordion
          ReactGA={ReactGA}
          sections={reference["sections"]}
          theme={theme}
        />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Reference;
