import React, { Component } from "react";
import "./ReferenceCard.css";

class ReferenceCard extends Component {
  render() {
    const reference = this.props.reference;
    const theme = this.props.theme;
    return (
      <div
        className="reference-card"
        style={{ border: `1px solid ${reference["color"]}` }}
      >
        <div className="reference-card-logo-div">
          {/* <img
            className="reference-card-logo"
            src={require(`../../assests/images/${reference["logo_path"]}`)}
            alt=""
          /> */}
        </div>
        <div className="reference-card-body-div">
          <div className="reference-card-header-div">
            <div className="reference-card-heading-left">
              <h3
                className="reference-card-title"
                style={{ color: theme.text }}
              >
                {reference["title"]}
              </h3>
              <p
                className="reference-card-company"
                style={{ color: theme.text }}
              >
                {/* <a
                  href={reference["company_url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                {reference["company"]}
                {/* </a> */}
              </p>
            </div>
            <div className="reference-card-heading-right">
              <p
                className="reference-card-location"
                style={{ color: theme.secondaryText }}
              >
                {reference["location"]}
              </p>
            </div>
          </div>
          {reference["descriptions"].map((description, index) => {
            return (
              // <ReferenceCard reference={reference} theme={theme} />
              <p
                className="reference-card-description"
                style={{ color: theme.text }}
                key={index}
              >
                {description}
              </p>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ReferenceCard;
