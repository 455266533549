import React, { Component } from "react";

export default class CloudInfraImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        id="4687a0c2-bd42-4093-8bbc-92d10a889ded"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width="866"
        height="720.37"
        viewBox="0 0 866 720.37"
      >
        <defs>
          <linearGradient
            id="01871234-c547-4dad-9a9e-117fcc2bb7d8"
            x1="660.5"
            y1="458.72"
            x2="660.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="gray" stopOpacity="0.25" />
            <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
            <stop offset="1" stopColor="gray" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="705039c9-bbaf-489f-8754-0d88ff1d230d"
            x1="802.94"
            y1="120.14"
            x2="802.94"
            y2="40.02"
          />
          <linearGradient
            id="f1b18cc7-88bb-4220-8574-cb67e65e6847"
            x1="802.94"
            y1="269.14"
            x2="802.94"
            y2="189.02"
          />
          <linearGradient
            id="729136a4-2a29-4999-b297-e6b2d53009e3"
            x1="802.94"
            y1="417.14"
            x2="802.94"
            y2="337.02"
          />
          <linearGradient
            id="7a22862c-2f66-4029-a70d-d91858435973"
            x1="475.1"
            y1="785.18"
            x2="475.1"
            y2="418.21"
          />
          <linearGradient
            id="668716b8-0ce2-4b29-be6e-2467fe221975"
            x1="475.5"
            y1="810.19"
            x2="475.5"
            y2="750.5"
          />
          <linearGradient
            id="032f3a0d-ef41-4ad6-8a52-90e2f3059f59"
            x1="459.55"
            y1="723.31"
            x2="459.55"
            y2="473.18"
          />
        </defs>
        <title>secure server</title>
        <g opacity="0.8">
          <rect
            x="455"
            width="411"
            height="458.72"
            fill="url(#01871234-c547-4dad-9a9e-117fcc2bb7d8)"
          />
        </g>
        <rect
          x="461.79"
          y="306.27"
          width="397.41"
          height="142.46"
          fill="#fff"
        />
        <rect
          x="494.41"
          y="340.06"
          width="15.4"
          height="73"
          fill={theme.imageHighlight}
        />
        <g opacity="0.7">
          <rect
            x="524.81"
            y="340.06"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.6">
          <rect
            x="555.22"
            y="340.06"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.5">
          <rect
            x="585.62"
            y="340.06"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.5">
          <rect
            x="616.02"
            y="340.06"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.4">
          <rect
            x="646.42"
            y="340.06"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.3">
          <rect
            x="676.83"
            y="340.06"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <circle cx="802.94" cy="376.09" r="37.16" fill={theme.jacketColor} />
        <rect
          x="461.79"
          y="158.26"
          width="397.41"
          height="142.46"
          fill="#fff"
        />
        <rect
          x="494.41"
          y="192.05"
          width="15.4"
          height="73"
          fill={theme.imageHighlight}
        />
        <g opacity="0.7">
          <rect
            x="524.81"
            y="192.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.6">
          <rect
            x="555.22"
            y="192.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.5">
          <rect
            x="585.62"
            y="192.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.5">
          <rect
            x="616.02"
            y="192.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.4">
          <rect
            x="646.42"
            y="192.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.3">
          <rect
            x="676.83"
            y="192.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <circle cx="802.94" cy="228.09" r="37.16" fill={theme.jacketColor} />
        <rect x="461.79" y="10.25" width="397.41" height="142.46" fill="#fff" />
        <rect
          x="494.41"
          y="44.05"
          width="15.4"
          height="73"
          fill={theme.imageHighlight}
        />
        <g opacity="0.7">
          <rect
            x="524.81"
            y="44.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.6">
          <rect
            x="555.22"
            y="44.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.5">
          <rect
            x="585.62"
            y="44.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.5">
          <rect
            x="616.02"
            y="44.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.4">
          <rect
            x="646.42"
            y="44.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <g opacity="0.3">
          <rect
            x="676.83"
            y="44.05"
            width="15.4"
            height="73"
            fill={theme.imageHighlight}
          />
        </g>
        <circle
          cx="802.94"
          cy="80.08"
          r="40.06"
          fill="url(#705039c9-bbaf-489f-8754-0d88ff1d230d)"
        />
        <circle
          cx="802.94"
          cy="229.08"
          r="40.06"
          fill="url(#f1b18cc7-88bb-4220-8574-cb67e65e6847)"
        />
        <circle
          cx="802.94"
          cy="377.08"
          r="40.06"
          fill="url(#729136a4-2a29-4999-b297-e6b2d53009e3)"
        />
        <circle cx="802.94" cy="80.08" r="37.16" fill={theme.jacketColor} />
        <g opacity="0.8">
          <path
            d="M750.13,785.18H200.07V434a15.8,15.8,0,0,1,15.8-15.8H734.32a15.8,15.8,0,0,1,15.8,15.8Z"
            transform="translate(-167 -89.81)"
            fill="url(#7a22862c-2f66-4029-a70d-d91858435973)"
          />
        </g>
        <path
          d="M56.45,334.85h503.3a15.8,15.8,0,0,1,15.8,15.8V695.37a0,0,0,0,1,0,0H40.65a0,0,0,0,1,0,0V350.65A15.8,15.8,0,0,1,56.45,334.85Z"
          fill="#fff"
        />
        <rect
          x="57.87"
          y="355.82"
          width="500.46"
          height="328.25"
          fill="#f5f5f5"
        />
        <rect
          x="59.79"
          y="371.76"
          width="498.34"
          height="295.32"
          fill="#e0e0e0"
          opacity="0.8"
        />
        <rect
          x="59.79"
          y="356.07"
          width="498.34"
          height="15.69"
          fill="#f5f5f5"
        />
        <circle cx="70.47" cy="363.67" r="3.8" fill="#ff5252" />
        <circle cx="80.93" cy="363.67" r="3.8" fill="#ff0" />
        <circle cx="91.4" cy="363.67" r="3.8" fill="#69f0ae" />
        <text x="-167" y="-89.81"></text>
        <g opacity="0.8">
          <path
            d="M765.52,808.77c-30.62,2.32-556.46,1.39-574.84,0C166.59,797.16,167,752.12,167,752.12l241.15-1.61.81,13.71H512.2l4-12.1H783.48S788.79,793.45,765.52,808.77Z"
            transform="translate(-167 -89.81)"
            fill="url(#668716b8-0ce2-4b29-be6e-2467fe221975)"
          />
        </g>
        <path
          d="M779.56,755.75H517.5a8,8,0,0,1-6.11,13.31h-100a8,8,0,0,1-6.11-13.31H170.63s-.4,39.12,23.39,49.2c18.15,1.21,537.56,2,567.8,0C784.81,791.64,779.56,755.75,779.56,755.75Z"
          transform="translate(-167 -89.81)"
          fill="#f5f5f5"
        />
        <circle cx="58.07" cy="674.4" r="3.23" fill="#e0e0e0" />
        <circle cx="67.75" cy="674.4" r="3.23" fill="#e0e0e0" />
        <circle cx="77.43" cy="674.4" r="3.23" fill="#e0e0e0" />
        <g opacity="0.7">
          <path
            d="M459.55,473.18l-97.06,59.73s0,123.2,97.06,190.39c97.06-67.2,97.06-190.39,97.06-190.39Z"
            transform="translate(-167 -89.81)"
            fill="url(#032f3a0d-ef41-4ad6-8a52-90e2f3059f59)"
          />
        </g>
        <path
          d="M459.55,480.23,368,536.6s0,116.26,91.6,179.67c91.6-63.41,91.6-179.67,91.6-179.67Z"
          transform="translate(-167 -89.81)"
          fill="#0275a2"
        />
        <path
          d="M461.11,480.23l-1.57,1v234l1.57,1.1c91.6-63.41,91.6-179.67,91.6-179.67Z"
          transform="translate(-167 -89.81)"
          opacity="0.05"
        />
        <path
          d="M459.55,493.85l-81,49.86s0,102.84,81,158.94c81-56.1,81-158.94,81-158.94Z"
          transform="translate(-167 -89.81)"
          fill="#0275a2"
        />
        <path
          d="M459.55,494.24V703c81-56.1,81-158.94,81-158.94Z"
          transform="translate(-167 -89.81)"
          opacity="0.08"
        />
        <polyline
          points="239 306.38 239 99.38 410 99.38"
          fill="none"
          stroke="#3ad29f"
          strokeMiterlimit="10"
          strokeWidth="2"
          strokeDasharray="12"
        />
        <polyline
          points="186 243.38 186 36.38 357 36.38"
          fill="none"
          stroke="#3ad29f"
          strokeMiterlimit="10"
          strokeWidth="2"
          strokeDasharray="12"
        />
        <polyline
          points="716 477.38 716 639.38 626 639.38"
          fill="none"
          stroke="#3ad29f"
          strokeMiterlimit="10"
          strokeWidth="2"
          strokeDasharray="12"
        />
        <polyline
          points="770 513.38 770 675.38 680 675.38"
          fill="none"
          stroke="#3ad29f"
          strokeMiterlimit="10"
          strokeWidth="2"
          strokeDasharray="12"
        />
      </svg>
    );
  }
}
