import React, { Component } from "react";

export default class EducationImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      <svg
        width="817.4"
        height="616.88"
        data-name="Layer 1"
        version="1.1"
        viewBox="0 0 817.4 616.88"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>certificate</title>
        <path
          d="m700.3 334.17c0 94.882-55.628 178.82-140.9 229.92-54.675 32.768-121.54 52.035-193.75 52.035-96.188 0-169.56-36.352-224.75-92.04-50.873-51.315-86.295-119.06-109.9-189.92-105.37-316.34 70.65-402.65 334.65-281.96 168.09 76.852 334.65 126.24 334.65 281.96z"
          fill="#0e6ba8"
          strokeWidth=".75"
        />
        <path
          d="m559.4 168.57v396.28c-54.675 32.768-121.54 52.035-193.75 52.035-96.188 0-169.56-36.352-224.75-92.04v-356.27z"
          fill="#f2f2f2"
          strokeWidth=".75"
        />
        <g fill="#3f3d56" strokeWidth=".75">
          <path d="m181.15 361.66a261.83 261.83 0 0 1 67.617-12.055 211.61 211.61 0 0 1 34.478 1.349 215.67 215.67 0 0 1 33.462 7.164c10.815 3.1314 21.482 6.7489 32.261 10a300.88 300.88 0 0 0 33.341 8.1361 282.66 282.66 0 0 0 33.877 4.0378c11.252 0.65396 22.615 0.78158 33.836-0.41716a103.52 103.52 0 0 0 30.443-7.717 92.978 92.978 0 0 0 24.758-15.88q2.7542-2.4586 5.2996-5.1339c1.001-1.0464-0.58764-2.6398-1.591-1.591-14.343 14.994-33.196 24.291-53.645 27.396-11.14 1.6913-22.492 1.7894-33.73 1.3533a277.55 277.55 0 0 1-34.286-3.4642 286.52 286.52 0 0 1-33.206-7.5584c-10.716-3.1045-21.286-6.6838-31.964-9.9111a240.55 240.55 0 0 0-32.909-7.8406 206.95 206.95 0 0 0-33.764-2.3436 258.15 258.15 0 0 0-66.729 9.8817q-4.0911 1.1487-8.1446 2.4244c-1.377 0.4317-0.78906 2.6045 0.59814 2.1696z" />
          <path d="m181.15 418.66a261.83 261.83 0 0 1 67.617-12.055 211.61 211.61 0 0 1 34.478 1.349 215.67 215.67 0 0 1 33.462 7.164c10.815 3.1314 21.482 6.7489 32.261 10a300.88 300.88 0 0 0 33.341 8.1361 282.66 282.66 0 0 0 33.877 4.0378c11.252 0.65396 22.615 0.78158 33.836-0.41716a103.52 103.52 0 0 0 30.443-7.717 92.978 92.978 0 0 0 24.758-15.88q2.7542-2.4586 5.2996-5.1339c1.001-1.0464-0.58764-2.6398-1.591-1.591-14.343 14.994-33.196 24.291-53.645 27.396-11.14 1.6913-22.492 1.7894-33.73 1.3533a277.55 277.55 0 0 1-34.286-3.4642 286.52 286.52 0 0 1-33.206-7.5584c-10.716-3.1045-21.286-6.6838-31.964-9.9111a240.55 240.55 0 0 0-32.909-7.8406 206.95 206.95 0 0 0-33.764-2.3436 258.15 258.15 0 0 0-66.729 9.8817q-4.0911 1.1487-8.1446 2.4244c-1.377 0.4317-0.78906 2.6045 0.59814 2.1696z" />
          <path d="m181.15 309.91a261.83 261.83 0 0 1 67.617-12.055 211.61 211.61 0 0 1 34.478 1.349 215.67 215.67 0 0 1 33.462 7.164c10.815 3.1314 21.482 6.7489 32.261 10a300.88 300.88 0 0 0 33.341 8.1361 282.66 282.66 0 0 0 33.877 4.0378c11.252 0.65396 22.615 0.78158 33.836-0.41716a103.52 103.52 0 0 0 30.443-7.717 92.978 92.978 0 0 0 24.758-15.88q2.7542-2.4586 5.2996-5.1339c1.001-1.0464-0.58764-2.6398-1.591-1.591-14.343 14.994-33.196 24.291-53.645 27.396-11.14 1.6913-22.492 1.7894-33.73 1.3533a277.55 277.55 0 0 1-34.286-3.4642 286.52 286.52 0 0 1-33.206-7.5584c-10.716-3.1045-21.286-6.6838-31.964-9.9111a240.55 240.55 0 0 0-32.909-7.8406 206.95 206.95 0 0 0-33.764-2.3436 258.15 258.15 0 0 0-66.729 9.8817q-4.0911 1.1487-8.1446 2.4244c-1.377 0.4317-0.78906 2.6045 0.59814 2.1696z" />
          <path d="m178.47 212.06c5.4438-2.4117 11.68-1.9911 17.33-0.59276 6.0918 1.5078 11.828 4.13 17.825 5.943a61.704 61.704 0 0 0 32.983 0.62568 60.797 60.797 0 0 0 17.274-7.4039c1.223-0.76875 0.0951-2.7164-1.1356-1.9428a59.616 59.616 0 0 1-31.74 9.0485 58.235 58.235 0 0 1-16.615-2.4465c-5.953-1.7849-11.644-4.3763-17.674-5.9134-6.3893-1.6284-13.241-1.9816-19.383 0.73931-1.3212 0.5853-0.17775 2.5246 1.1356 1.9428z" />
          <circle cx="345.65" cy="522.57" r="34.5" />
        </g>
        <g fill="#f2f2f2" strokeWidth=".75">
          <circle cx="159.65" cy="79.317" r="18.75" />
          <circle cx="323.9" cy="132.57" r="12.75" />
          <circle cx="116.9" cy="254.82" r="12.75" />
        </g>
        <g fill="#3f3d56" strokeWidth=".75">
          <rect x="64.16" y="160.1" width="1.406" height="251.43" />
          <ellipse cx="65.134" cy="327.42" rx="27.418" ry="62.568" />
          <ellipse cx="65.134" cy="184.7" rx="27.418" ry="62.568" />
          <path d="m65.808 133.59-1.347-0.40368c0.0851-0.28425 8.351-28.61-5.2215-46.846-7.6659-10.299-20.82-15.521-39.098-15.521v-1.406c18.749 0 32.284 5.4144 40.23 16.093 13.993 18.807 5.5239 47.793 5.4367 48.083z" />
          <circle cx="20.142" cy="70.112" r="17.575" />
        </g>
        <g strokeWidth=".75">
          <path
            d="m578.05 468.51-13.149 120.97h90.731l3.9448-9.2046 5.2598 9.2046h85.471s-6.5747-124.92-18.409-128.86c-11.834-3.9448-153.85 7.8896-153.85 7.8896z"
            fill="#2f2e41"
          />
          <circle cx="655.64" cy="156.87" r="46.023" fill="#ffb8b8" />
          <path
            d="m629.34 185.8s3.9448 42.078 0 44.708c-3.9448 2.6299 63.117 1.3149 63.117 1.3149s-9.2046-38.133 0-56.542z"
            fill="#ffb8b8"
          />
        </g>
        <g strokeWidth=".75">
          <path
            d="m653.01 221.3s33.835 5.2698 36.642-5.2547l17.271 23.664 1.3149 236.69s-140.7 17.094-145.96-10.52c-5.2598-27.614 53.912-245.89 53.912-245.89l14.897-0.96429s19.292 2.2792 21.922 2.2792z"
            fill="#0e6ba8"
          />
          <path
            d="m630.86 208.22-22.562 7.8183-64.432 15.779 2.6299 157.79s-55.227 157.79-30.244 169.63c24.984 11.834 60.487-15.779 71.007-35.503 10.52-19.724 47.338-293.23 47.338-298.49s-3.7364-17.023-3.7364-17.023z"
            fill="#0a2472"
          />
          <path
            d="m688.8 208.22 8.9116 7.8183 63.117 28.929-31.558 159.11s42.078 144.64 26.299 152.53-39.448 30.244-44.708 17.094-36.232-350.95-22.061-365.48z"
            fill="#0a2472"
          />
        </g>
        <g strokeWidth=".75">
          <path
            d="m725.33 418.54s-59.172-24.984-60.487-2.6299c-1.3149 22.354 67.062 38.133 67.062 31.558s-6.5747-28.929-6.5747-28.929z"
            fill="#ffb8b8"
          />
          <path
            d="m580.68 409.34s68.377 51.283 42.078 64.432c-26.299 13.149-60.487-46.023-60.487-46.023z"
            fill="#ffb8b8"
          />
          <path
            d="m676.07 89.451a2.5384 2.5384 0 0 1 2.4426-1.4496c-21.07-1.2603-43.263 1.1954-60.891 12.806-4.9367 3.2518-9.5469 7.2282-15.167 9.0608l4.562 3.5776-5.4507 1.6616 8.0216 5.1845a6.9528 6.9528 0 0 1-6.6215 2.2183c3.4861 1.0185 6.0528 4.1208 7.3565 7.5106s1.5379 7.0816 1.7581 10.707c1.3474-5.2307 7.056-8.3098 12.448-8.6226s10.651 1.4663 15.899 2.7453a87.954 87.954 0 0 0 34.911 1.343c-3.9424 7.9116-0.61681 17.348 2.7297 25.53l6.6819 16.336c3.7097 0.37312 6.3318-3.3161 9.1266-5.7838 2.5473-2.2492-7.5941 17.532-6.0239 16.808 2.0399-0.94154 23.566-24.708 24.574-26.716 4.5991-9.1606 7.3584-19.378 6.9434-29.62-0.41501-10.242-4.1625-20.489-11.222-27.921-4.7927-5.0454-11.423-5.929-16.31-10.122-1.9231-1.6504-1.9301-2.9398-4.5729-3.902-0.8778-0.31959-11.052-1.6736-11.197-1.3515z"
            fill="#2f2e41"
          />
          <path
            d="m553.07 235.76-9.2045-3.9448-73.637 120.97s-13.149 31.558 10.52 46.023 78.896 57.857 78.896 57.857l31.558-48.653s-64.432-30.244-60.487-42.078c3.9448-11.834 24.984-43.393 24.984-43.393z"
            fill="#0a2472"
          />
          <path
            d="m743.74 242.34 17.094 2.6299s71.007 139.38 53.912 165.68c-17.094 26.299-86.786 63.117-86.786 63.117s-1.3149-43.393-13.149-59.172l40.763-15.779-22.354-86.786z"
            fill="#0a2472"
          />
        </g>
      </svg>
    );
  }
}
