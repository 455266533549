import React from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme } from "./theme";
import { GlobalStyles } from "./global";

import ReactGA from "react-ga";
ReactGA.initialize("UA-191697220-1");

function App() {
  return (
    <ThemeProvider theme={chosenTheme}>
      <>
        <GlobalStyles />
        <div>
          <Main ReactGA={ReactGA} theme={chosenTheme} />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
