import React, { Component } from "react";

export default class ReferenceImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      // <svg
      //   id="af461e34-156f-4911-b433-1993d7c9d72f"
      //   data-name="Layer 1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   width="1008.92039"
      //   height="607.44997"
      //   viewBox="0 0 1008.92039 607.44997"
      // >
      //   <path
      //     d="M1104.46,620.305a15.34,15.34,0,0,1-15.25952,15.42H417.8a15.34,15.34,0,0,1-15.26-15.41955V161.695a15.34,15.34,0,0,1,15.25958-15.42H1089.2a15.34,15.34,0,0,1,15.26,15.41957h0V620.305Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#e6e6e6"
      //   />
      //   <path
      //     d="M1096.54006,612.795a14.91,14.91,0,0,1-14.91,14.91H425.83a14.91,14.91,0,0,1-14.91-14.91V169.415a14.91,14.91,0,0,1,14.91-14.91h655.83a14.91,14.91,0,0,1,14.88,14.91Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="a90d98f2-fe2a-41dc-85de-0aa221261a3e"
      //     data-name="b9c54412-061c-4155-b289-fb4c23e4a64e"
      //     d="M445.97,173.755h616.22a8.85,8.85,0,0,1,8.85,8.85v415.28a8.85,8.85,0,0,1-8.85,8.85H445.97a8.85,8.85,0,0,1-8.85-8.85h0V182.605a8.85,8.85,0,0,1,8.85-8.85Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#e6e6e6"
      //   />
      //   <path
      //     d="M453.77,183.715h599.95a8.85,8.85,0,0,1,8.85,8.85v393.12a8.85,8.85,0,0,1-8.85,8.85H453.77a8.85,8.85,0,0,1-8.85-8.85h0V192.565a8.85,8.85,0,0,1,8.85-8.85Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     d="M949.46,675.305a15.34,15.34,0,0,1-15.25958,15.42H262.8a15.34,15.34,0,0,1-15.26-15.41961h0V216.695a15.34,15.34,0,0,1,15.25957-15.42H934.2a15.34,15.34,0,0,1,15.26,15.41957h0V675.305Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#e6e6e6"
      //   />
      //   <path
      //     d="M941.54,667.795a14.91,14.91,0,0,1-14.91,14.91H270.83a14.91,14.91,0,0,1-14.91-14.91V224.415a14.91,14.91,0,0,1,14.91-14.91H926.66a14.91,14.91,0,0,1,14.88,14.91Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="b54624cf-b287-45f0-8f58-93aafc1fcbba"
      //     data-name="a7242049-80b4-49e1-bd08-67354734c824"
      //     d="M290.97,228.755H907.19a8.85,8.85,0,0,1,8.85,8.85v415.28a8.85,8.85,0,0,1-8.85,8.85H290.97a8.85,8.85,0,0,1-8.85-8.85h0V237.605a8.85,8.85,0,0,1,8.85-8.85h0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#e6e6e6"
      //   />
      //   <path
      //     d="M298.77,238.715H898.72a8.85,8.85,0,0,1,8.85,8.85v393.12a8.85,8.85,0,0,1-8.85,8.85H298.77a8.85,8.85,0,0,1-8.85-8.85h0V247.565a8.85,8.85,0,0,1,8.85-8.85Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     d="M797.46,738.305a15.34,15.34,0,0,1-15.25958,15.42H110.8a15.34,15.34,0,0,1-15.26-15.41955V279.695a15.34,15.34,0,0,1,15.25957-15.42H782.2a15.34,15.34,0,0,1,15.26,15.41959h0V738.305Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#e6e6e6"
      //   />
      //   <path
      //     d="M789.54,730.795a14.91,14.91,0,0,1-14.91,14.91H118.83a14.91,14.91,0,0,1-14.91-14.91V287.415a14.91,14.91,0,0,1,14.91-14.91H774.66a14.91,14.91,0,0,1,14.88,14.91Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="a665a07e-790f-4a9c-be99-8c13ca89238c"
      //     data-name="b7a050eb-0cb2-44a8-b642-35d340850eff"
      //     d="M138.97,291.755H755.19a8.85,8.85,0,0,1,8.85,8.85v415.28a8.85,8.85,0,0,1-8.85,8.85H138.97a8.85,8.85,0,0,1-8.85-8.85V300.605a8.85,8.85,0,0,1,8.85-8.85h0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#e6e6e6"
      //   />
      //   <path
      //     d="M146.77,301.715H746.72a8.85,8.85,0,0,1,8.85,8.85v393.12a8.85,8.85,0,0,1-8.85,8.85H146.77a8.85,8.85,0,0,1-8.85-8.85V310.565A8.85,8.85,0,0,1,146.77,301.715Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     d="M590.14174,386.874v272a6.00477,6.00477,0,0,1-6,6h-285a6.00476,6.00476,0,0,1-6-6v-304a6.00475,6.00475,0,0,1,6-6h249Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#0595ce"
      //   />
      //   <path
      //     id="b3cdc3b1-5d0a-4cee-b54c-a38ee088c012"
      //     data-name="Path 40"
      //     d="M343.57782,415.072a3.755,3.755,0,0,0,0,7.50892h99.00454a3.755,3.755,0,1,0,.12329-7.50892q-.06165-.001-.12329,0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="b79c0997-055d-434d-8704-cfc1ccad2281"
      //     data-name="Path 40"
      //     d="M343.57782,450.291a3.755,3.755,0,0,0,0,7.50893H539.58236a3.755,3.755,0,0,0,.12329-7.50893q-.06165-.001-.12329,0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="ed7d1f14-d1d4-40af-b9ad-7b5b5ece23df"
      //     data-name="Path 40"
      //     d="M343.57782,485.51005a3.755,3.755,0,0,0,0,7.50893H539.58236a3.755,3.755,0,1,0,.12329-7.50893q-.06165-.001-.12329,0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="ba3b747a-a6b2-4f39-a169-6ef4c21cd2ce"
      //     data-name="Path 40"
      //     d="M343.57782,520.72906a3.755,3.755,0,0,0,0,7.50892H539.58236a3.755,3.755,0,1,0,.12329-7.50892q-.06165-.001-.12329,0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="b6e4b743-2497-479e-800a-91c8b21b3df9"
      //     data-name="Path 40"
      //     d="M343.57782,555.94807a3.755,3.755,0,0,0,0,7.50892H539.58236a3.755,3.755,0,1,0,.12329-7.50892q-.06165-.00105-.12329,0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     id="f91f499d-e7c9-446d-84c9-bf78465468f6"
      //     data-name="Path 40"
      //     d="M343.57782,591.16708a3.755,3.755,0,0,0,0,7.50892H539.58236a3.755,3.755,0,1,0,.12329-7.50892q-.06165-.00105-.12329,0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#fff"
      //   />
      //   <path
      //     d="M590.14174,386.874h-38a4,4,0,0,1-4-4v-34h0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     fill="#0595ce"
      //   />
      //   <path
      //     d="M590.14174,386.874h-38a4,4,0,0,1-4-4v-34h0Z"
      //     transform="translate(-95.53981 -146.27502)"
      //     opacity="0.2"
      //   />
      // </svg>
      <svg
        id="a103f326-996f-4fea-8e69-572a052820ff"
        width="825.77"
        height="640.56"
        data-name="Layer 1"
        version="1.1"
        viewBox="0 0 825.77 640.56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>performance_overview</title>
        <polygon
          points="479.74 627.22 288.49 627.22 294.97 542.94 473.25 542.94"
          fill="#e6e6e6"
        />
        <rect
          x="278.76"
          y="617.5"
          width="213.94"
          height="19.449"
          fill="#e6e6e6"
        />
        <path
          transform="translate(-187.12 -129.72)"
          d="m937.51 129.72h-730.95a19.449 19.449 0 0 0-19.449 19.449v445.7h769.84v-445.7a19.449 19.449 0 0 0-19.449-19.449z"
          fill="#3f3d56"
        />
        <path
          transform="translate(-187.12 -129.72)"
          d="m187.12 594.87v72.933a19.449 19.449 0 0 0 19.449 19.449h730.95a19.449 19.449 0 0 0 19.449-19.449v-72.933z"
          fill="#e6e6e6"
        />
        <rect
          x="38.897"
          y="40.518"
          width="693.67"
          height="388.97"
          fill="#e6e6e6"
        />
        <circle cx="385.73" cy="510.53" r="19.449" fill="#3f3d56" />
        <g fill="#fff">
          <circle cx="60.526" cy="53.257" r="3.6909" />
          <circle cx="74.536" cy="53.257" r="3.6909" />
          <circle cx="88.545" cy="53.257" r="3.6909" />
        </g>
        <path
          transform="translate(-187.12 -129.72)"
          d="m983.46 392.78s5.9448 77.282-2.3779 79.66-38.046 20.212-85.605 15.456c0 0-46.369 24.968-39.235 1.189s39.235-17.834 39.235-17.834 57.07-11.89 59.448-20.212-7.1337-42.802-3.5668-43.991 32.102-14.267 32.102-14.267z"
          fill="#ffb8b8"
        />
        <path
          transform="translate(-187.12 -129.72)"
          d="m979.46 393.78s5.9448 77.282-2.3779 79.66-38.046 20.212-85.605 15.456c0 0-46.369 24.968-39.235 1.189s39.235-17.834 39.235-17.834 57.07-11.89 59.448-20.212-7.1337-42.802-3.5668-43.991 32.102-14.267 32.102-14.267z"
          fill="#231f20"
          opacity=".2"
        />
        <g fill="#2f2e41">
          <path
            transform="translate(-187.12 -129.72)"
            d="m912.7 518.1s4.1777 130.07 11.311 146.71c5.9421 13.865 9.4095 59.077 10.402 73.795 0.199 2.9513 0.29855 4.6762 0.29855 4.6762s19.023 0 20.212-4.7558 4.7558-57.07 0-73.715 6.7758-139.71 6.7758-139.71z"
          />
          <path
            transform="translate(-187.12 -129.72)"
            d="m939.47 737.34s-11.89 3.5669-14.267 8.3227-41.613 13.078-29.724 19.023 47.558-4.7558 47.558-4.7558 19.023-1.189 17.834-8.3227-5.5188-15.924-5.5188-15.924z"
          />
          <path
            transform="translate(-187.12 -129.72)"
            d="m972.76 741.14s-11.89 3.5668-14.267 8.3227-41.613 13.078-29.724 19.023 47.558-4.7558 47.558-4.7558 19.023-1.189 17.834-8.3227-5.5188-15.924-5.5188-15.924z"
          />
          <path
            transform="translate(-187.12 -129.72)"
            d="m922.82 524.75 16.645 28.535 27.78 99.21s-2.8119 91.022 0.755 91.022 22.59 5.9448 23.779 0c1.0043-5.0216 14.321-53.125 7.6832-85.143a409.42 409.42 0 0 1-8.308-61.426l-2.942-55.553s11.89-21.401-1.189-24.968-64.203 8.3226-64.203 8.3226z"
          />
        </g>
        <circle cx="751.16" cy="183.4" r="28.535" fill="#ffb8b8" />
        <path
          transform="translate(-187.12 -129.72)"
          d="m934.71 332.14s4.7558 38.046 3.5669 40.424 28.535-26.157 28.535-26.157-10.701-16.645-8.3227-24.968z"
          fill="#ffb8b8"
        />
        <path
          transform="translate(-187.12 -129.72)"
          d="m1001.3 370.19s-33.291-23.779-34.48-28.535l-24.968 21.401-4.2426-5.0501s-19.536 19.318-19.536 27.64-1.189 33.291 2.3779 40.424-10.701 97.494-10.701 97.494 11.89 10.701 13.078 11.89 48.747-7.1337 48.747-7.1337 23.779-2.3779 23.779-5.9448-7.1337-57.07-4.7558-71.337 10.701-80.849 10.701-80.849z"
          fill="#0e6ba8"
        />
        <path
          transform="translate(-187.12 -129.72)"
          d="m926.09 298.86c3.9005-1.3882 8.2035-2.1012 12.181-0.95035 5.6287 1.6288 9.5271 6.5627 13.732 10.644s10.101 7.6976 15.637 5.7784a9.168 9.168 0 0 1 1.7514-6.771 6.227 6.227 0 0 0 1.5551-2.5882c0.23654-1.5283-1.0486-2.8465-1.434-4.3442-0.67535-2.6246 1.176-6.2176-1.1002-7.6886-0.60082-0.3883-1.3615-0.47683-1.9479-0.88664-2.3012-1.6084 0.25422-5.6087-1.2673-7.9683a4.8663 4.8663 0 0 0-1.7289-1.4423c-11.033-6.4375-24.525-6.4552-37.297-6.293-1.7781 0.02258-4.2535 0.90568-3.752 2.6117-2.4138-1.7327-5.5746 0.70029-7.5202 2.946l-3.9823 4.5964a35.41 35.41 0 0 0-4.8667 6.5831c-1.2165 2.3264-1.4855 5.9029 0.9356 6.9171-1.8-0.754-3.9835 9.9076 4.3145 8.1534 4.8213-1.0192 9.5471-7.4314 14.791-9.2976z"
          fill="#2f2e41"
        />
        <rect
          x="56.835"
          y="65.798"
          width="655.74"
          height="326.67"
          fill="#fff"
        />
        <g fill="#e6e6e6">
          <rect x="96.424" y="113.81" width="143.32" height="125.18" />
          <rect x="313.7" y="114.18" width="142.89" height="124.8" />
          <rect x="528.7" y="112.96" width="144.29" height="126.02" />
          <rect x="96.424" y="293.62" width="140.18" height="12.949" />
        </g>
        <rect
          x="96.424"
          y="316.42"
          width="70.232"
          height="12.949"
          fill="#0e6ba8"
        />
        <rect
          x="316.03"
          y="293.62"
          width="140.18"
          height="12.949"
          fill="#e6e6e6"
        />
        <rect
          x="316.03"
          y="316.42"
          width="70.232"
          height="12.949"
          fill="#0e6ba8"
        />
        <rect
          x="530.15"
          y="293.62"
          width="140.18"
          height="12.949"
          fill="#e6e6e6"
        />
        <g fill="#0e6ba8">
          <rect x="530.15" y="316.42" width="70.232" height="12.949" />
          <circle cx="359.59" cy="266.26" r="6.6175" />
          <circle cx="384.71" cy="266.26" r="6.6175" />
          <circle cx="409.82" cy="266.26" r="6.6175" />
          <circle cx="142.97" cy="266.26" r="6.6175" />
          <circle cx="168.08" cy="266.26" r="6.6175" />
          <circle cx="193.2" cy="266.26" r="6.6175" />
          <circle cx="581.55" cy="266.26" r="6.6175" />
          <circle cx="606.67" cy="266.26" r="6.6175" />
          <circle cx="631.79" cy="266.26" r="6.6175" />
          <path
            transform="translate(-187.12 -129.72)"
            d="m787.96 269a36.686 36.686 0 1 0 36.686 36.686 36.794 36.794 0 0 0-36.686-36.686zm0 11.006a11.006 11.006 0 1 1-11.006 11.006 11.043 11.043 0 0 1 11.006-11.006zm0 52.983a26.726 26.726 0 0 1-22.012-11.747c0.17638-7.3372 14.674-11.376 22.012-11.376s21.835 4.039 22.012 11.376a26.77 26.77 0 0 1-22.012 11.747z"
          />
          <path
            transform="translate(-187.12 -129.72)"
            d="m572.26 269.61a36.686 36.686 0 1 0 36.686 36.686 36.794 36.794 0 0 0-36.686-36.686zm0 11.006a11.006 11.006 0 1 1-11.006 11.006 11.043 11.043 0 0 1 11.006-11.006zm0 52.983a26.726 26.726 0 0 1-22.012-11.747c0.17637-7.3372 14.674-11.376 22.012-11.376s21.835 4.039 22.012 11.376a26.77 26.77 0 0 1-22.012 11.747z"
          />
          <path
            transform="translate(-187.12 -129.72)"
            d="m355.2 269.43a36.686 36.686 0 1 0 36.686 36.686 36.794 36.794 0 0 0-36.686-36.686zm0 11.006a11.006 11.006 0 1 1-11.006 11.006 11.043 11.043 0 0 1 11.006-11.006zm0 52.983a26.726 26.726 0 0 1-22.012-11.747c0.17638-7.3372 14.674-11.376 22.012-11.376s21.835 4.039 22.012 11.376a26.77 26.77 0 0 1-22.012 11.747z"
          />
        </g>
        <path
          transform="translate(-187.12 -129.72)"
          d="m1008.4 403.48s5.9448 77.282-2.3779 79.66-38.046 20.212-85.605 15.456c0 0-46.369 24.968-39.235 1.189s39.235-17.834 39.235-17.834 57.07-11.89 59.448-20.212-7.1337-42.802-3.5668-43.991 32.102-14.267 32.102-14.267z"
          fill="#ffb8b8"
        />
        <path
          transform="translate(-187.12 -129.72)"
          d="m982.27 372.56 19.023-2.3779s19.023 36.858 8.3227 42.802-38.046 10.701-38.046 8.3227 10.701-48.747 10.701-48.747z"
          fill="#0e6ba8"
        />
      </svg>
    );
  }
}
